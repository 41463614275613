<template>
  <!-- 角色管理 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="角色名称：">
        <el-input maxlength="15" placeholder="请输入角色名称" :value="queryForm.role_name"
          @input="(e) => (queryForm.role_name = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="角色管理">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="deletefu" v-if="isShowBtn('b900d7063d614e76891967ae')">删除
          </lbButton>
          <lbButton icon="xinzeng" @click="addfu" v-if="!isAdmin && isShowBtn('55f92bba311d4c899244e0bc')">新增</lbButton>
        </div>
      </template>
      <!-- 主要内容 -->
      <div>
        <!-- 表格 -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" border stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column v-if="isAdmin" prop="group_name" label="集团名称"></el-table-column>
          <el-table-column prop="role_name" label="角色名称"></el-table-column>
          <el-table-column prop="role_desc" label="角色描述"></el-table-column>
          <el-table-column v-if="isAdmin" prop="create_user_name" label="创建者"></el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              <span>
                {{ validDateTime(scope.row.create_time) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作" v-if="isShowBtn('e7ecb44e75a64aaa90c4ddb0')">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="editit(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="TotalCount"></el-pagination>
      </div>
    </le-card>
    <!-- 角色管理 -->
    <lebo-dialog :isShow="showRoleEdit" width="90%" title="角色管理" :footerSlot="true" @close="showRoleEdit = false">
      <roleEdit v-if="showRoleEdit" @closeDialog="closeRoleEdit"></roleEdit>
    </lebo-dialog>
  </div>
</template>

<script>
import request from '@/api/systemapi'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import roleEdit from './components/roleEdit/index.vue'
export default {
  components: { roleEdit },
  data () {
    return {
      // 查询表单
      queryForm: {
        role_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 表格数据
      tableData: [],
      dialogForm: {
        name: ''
      },
      dialogRules: {
        name: [{ required: true, message: '请输集团名称', trigger: 'blur' }]
      },
      TotalCount: 0,
      role_id: [],
      showRoleEdit: false
    }
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['isAdmin'])
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.requestRoleManagerList()
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.requestRoleManagerList()
    },
    // 每页条数改变
    handleSizeChange (size) {
      this.queryForm.PageSize = size
      this.requestRoleManagerList()
    },
    // 页码改变
    handleCurrentChange (page) {
      this.queryForm.PageIndex = page
      this.requestRoleManagerList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0
    },
    // 编辑
    editit (row) {
      sessionStorage.setItem('role-edit', JSON.stringify({ row: row, isedit: true }))
      this.showRoleEdit = true
    },
    restPassWord () {
      // 重置密码
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (oldArray) {
      if (oldArray.lenght === 0) {
        this.role_id = []
      } else {
        var newArray = []
        for (const item of oldArray) {
          newArray.push(item._id)
        }
        this.role_id = newArray
      }
    },
    addfu () {
      this.showRoleEdit = true
    },
    deletefu () {
      var that = this
      if (this.role_id.length === 0) {
        this.$msg.warning('请选择要删除的条目！')
        return
      }
      this.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          that.requestDeleteRole()
        })
        .catch(() => {
          this.$msg.info('已取消删除')
        })
    },
    async requestRoleManagerList () {
      const res = await request.roleManagerList(this.queryForm)
      this.tableData = res.Data.DataList
      this.TotalCount = res.Data.TotalCount
    },
    async requestDeleteRole () {
      const res = await request.deleteRole({
        _ids: this.role_id
      })
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.requestRoleManagerList()
    },
    closeRoleEdit () {
      this.showRoleEdit = false
      this.requestRoleManagerList()
    }
  }
}
</script>

<style scoped lang="less">
.demo-form-inline {
  /* float: left; */
  display: flex;
  justify-content: start;
}

/* // .el-input__inner {
//   height: 36px;
//   line-height: 36px;
// } */
.button_box {
  display: flex;
}

.button_box .el-button {
  margin-left: 15px;
  width: auto;
}

.el-dialog__body {
  padding: 20px;
}

.el-button {
  width: auto !important;
}</style>
