<template>
  <div class="box-card">
    <!-- 主要内容 -->
    <div class="contents">
      <div class="titlediv">角色信息</div>
      <el-divider></el-divider>
      <el-form :model="addForm" :rules="rules" ref="queryFormRef" label-width="100px">
        <el-form-item label="角色名称：" prop="role_name" style="width: 30%">
          <el-input maxlength="15" placeholder="请输入角色名称" :value="addForm.role_name"
            @input="(e) => (addForm.role_name = validSpace(e))"></el-input>
        </el-form-item>
        <el-form-item label="角色描述：" prop="role_desc" style="width: 30%">
          <el-input maxlength="50" placeholder="请输入角色描述" :value="addForm.role_desc"
            @input="(e) => (addForm.role_desc = validSpace(e))"></el-input>
        </el-form-item>
      </el-form>
      <div class="titlediv">权限分配</div>
      <el-divider></el-divider>
      <div class="tree-style">
        <!-- default-expand-all -->
        <el-tree :data="treeSelectList" :default-checked-keys="menuList" :props="defaultProps" show-checkbox
          ref="treeRefs" node-key="menu_id" :highlight-current="true" :expand-on-click-node="false" default-expand-all
          @check="handleCheck" id="treeTable" :render-content="renderContent">

        </el-tree>
      </div>
    </div>
    <!-- <lbButton type="goBack" icon="back" class="checkButton2" @click="defaultForm">返 回</lbButton> -->
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitForm" v-preventReClick>保 存</lbButton>
  </div>
</template>

<script>
import request from '@/api/systemapi'
import { mapMutations, mapGetters } from 'vuex'
export default {
  data () {
    const checkUser = (rule, value, callback) => {
      this.requestVerifyRoleNameisReuse(callback)
    }
    return {
      // 标题
      title: '',
      // 树控件列表
      treeSelectList: [],
      defaultProps: {
        children: 'children',
        label: 'menu_name',
        disabled: function (data, node) {
          var index = node.parent.childNodes.filter(item => item.checked)
          if (data.menu_name === '查看' && index.length > 1) {
            return true
          } else {
            return false
          }
        }
      },
      menuList: [],
      // 提交数据列表
      addForm: {
        role_name: '',
        role_desc: '',
        children: [], // 集团id
        menu_list: [], // 集团id
        screen_id_list: [] // 大屏id
      },
      rules: {
        role_name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { validator: checkUser, trigger: 'blur' }
        ],
        role_desc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' }
        ],
        role_id: [{ required: true, message: '请选择角色', trigger: 'change' }],
        group_id: [
          { required: true, message: '请选择集团', trigger: 'change' }
        ]
      },
      // 是否是编辑角色
      isedit: false,
      // 角色id
      id: ''
    }
  },
  created () {
  },
  watch: {
    treeSelectList (newVal, oldVal) {
    }
  },
  mounted () {
    const storeItem = JSON.parse(sessionStorage.getItem('role-edit'))
    if (storeItem) {
      // console.log('storeItem', storeItem)
      const { row, isedit } = storeItem
      if (row) {
        if (isedit) {
          this.isedit = true
          const {
            _id = '',
            // eslint-disable-next-line camelcase
            role_name = '',
            // eslint-disable-next-line camelcase
            role_desc = '',
            children = [],
            // eslint-disable-next-line camelcase
            menu_list = [],
            // eslint-disable-next-line camelcase
            screen_id_list = []
          } = row
          this.id = _id
          this.addForm = {
            role_name: role_name,
            role_desc: role_desc,
            children: children, // 菜单子集id
            menu_list: menu_list, // 菜单选中id
            screen_id_list: screen_id_list
          }
          this.addForm.children = this.deepClone(this.treeSelectList)
          // console.log('screen_id_list', screen_id_list)
          // console.log('this.treeSelectList', this.treeSelectList)
          // console.log(' this.deepClone(this.treeSelectList)', this.deepClone(this.treeSelectList)) // 进入时给赋值
          // console.log('menu_list', this.deepClone(menu_list))
          // eslint-disable-next-line camelcase
          this.menuList = [...this.deepClone(menu_list), ...screen_id_list]
        }
      }
      this.title = '编辑角色'
    } else {
      this.title = '新增角色'
    }
    this.requestRoleDetailList()
    this.$nextTick(() => {
      // 表格样式
      var threeFuse = document.getElementsByClassName('threeFuse') // 3级按钮
      var threeFuseFrank = document.getElementsByClassName('threeFuseFrank')// 2级按钮
      setTimeout(() => {
        this.addTreeStyle(threeFuse, threeFuseFrank)
      }, 500)
    })
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    defaultForm () {
      this.setQueryStatus(2)
      this.$emit('closeDialog', false)
    },
    // 当复选框被点击的时候触发
    // 当复选框被点击的时候触发
    handleCheck (data, node) {
      // console.log('当复选框被点击的时候触发--data------', data)
      // console.log('当复选框被点击的时候触发----------node------', node)
      var checkedKeys = node.checkedKeys // 选中的节点
      // 判断是否是选中状态
      var index = checkedKeys.findIndex((item) => item === data.menu_id)
      // console.log(index);
      // 如果是取消选中
      if (index < 0) {
        // 判断是否是按钮层级取消选中，
        // 若是， 判断此时是否只有查看按钮选中 若只有查看按钮选中，则查看按钮不禁用 若还有其它按钮选中，则查看按钮禁用
        // 若不是，则取消查看按钮禁用,并取消查看按钮选中
        if (data.is_btn) {
          if (data.menu_name !== '查看') {
            // // 获取按钮所在层级数组，this.$refs.treeRefs.getNode(data.id)---获取当前节点node； parent----父级node； data---父级数据
            // const children = this.$refs.treeRefs.getNode(data.id).parent.data.children;
            // console.log('children********', children);
            // var num = 0;
            // checkedKeys.forEach(item => {
            //   children.forEach(data => {
            //     if (data.id === item) num++;
            //   })
            // })
            // console.log(num);
            // const id = children.find(item => item.name === '查看').id;
            // // 遍历数据，设置查看按钮禁用
            // this.treeSelectList = this.setDisabledLookNode(this.treeSelectList.concat(), id, num > 1);
          }
        } else {
          // 设置子节点的查看按钮取消禁用
          // this.treeSelectList = this.setDisabledLookNode2(this.treeSelectList.concat(), data.id, false);
          // console.log(this.treeSelectList);
          // 取消所有子节点的选中
          // var children = [];
          // children.push(data);
          if (data.children) {
            this.checkedChildrenNode(data.children, checkedKeys)
          }
        }
      } else {
        // 判断是否是按钮层级选中
        // 若是，判断是否是查看按钮选中，若不是，则选中查看按钮并禁用，若是，则不做处理
        // 若不是，则设置其子节点选中，并禁用有按钮级有多个按钮的查看按钮
        if (data.is_btn) {
          if (data.menu_name !== '查看') {
            // 获取按钮所在层级数组，this.$refs.treeRefs.getNode(data.id)---获取当前节点node； parent----父级node； data---父级数据
            const children = this.$refs.treeRefs.getNode(data.menu_id).parent.data.children
            // console.log('children********', children);
            const id = children.find(item => item.menu_name === '查看').menu_id
            checkedKeys.push(id)
            // // 遍历数据，设置查看按钮禁用
            // this.treeSelectList = this.setDisabledLookNode(this.treeSelectList.concat(), id, true);
          }
        } else {
          // // 遍历数据，设置查看按钮禁用
          // this.treeSelectList = this.setDisabledLookNode2(this.treeSelectList.concat(), data.id, true);
        }
      }
      // 剔除所选大屏的点
      const checkNodes = node.checkedNodes
      this.addForm.screen_id_list = []
      console.log('checkNodes.length', checkNodes.length)
      checkNodes.forEach(item => {
        if (item.is_screen) {
          this.addForm.screen_id_list.push(item.menu_id) // 添加大屏id
        }
      })

      console.log(' this.addForm.screen_id_list', this.addForm.screen_id_list)
      // 设置节点选中状态
      this.$refs.treeRefs.setCheckedKeys(checkedKeys)
      // console.log('getCheckedKeys---------', this.$refs.treeRefs.getCheckedKeys());
      // console.log('getHalfCheckedKeys-----', this.$refs.treeRefs.getHalfCheckedKeys());
      var halfCheckedKeys = this.$refs.treeRefs.getHalfCheckedKeys()
      // 将选中和半选中的节点添加到表单对象中
      // console.log('将选中和半选中的节点halfCheckedKeys', halfCheckedKeys)
      const finalFilterScreen = checkedKeys.concat(halfCheckedKeys).filter(item => {
        if (item !== '000000000000000000000000' && this.addForm.screen_id_list.indexOf(item) === -1) {
          return item
        }
      })
      // console.log('finalFilterScreen', finalFilterScreen)
      // this.addForm.menu_list = checkedKeys.concat(halfCheckedKeys)
      this.addForm.menu_list = finalFilterScreen
      // console.log('将选中和半选中的节点menu_list', this.addForm.menu_list)
      // this.addForm.menu_list = this.$refs.treeRefs.getCheckedKeys();
      // console.log('getCheckedKeys---------', this.addForm.menu_list);
      // const filterMenue = this.treeSelectList.filter(item => {
      //   return !item.is_screen
      // })
      // this.addForm.children = this.treeSelectList
      // this.addForm.children = filterMenue
      // console.log('this.addForm.children =2', this.addForm.children)
      // this.treeSelectList = this.setDisabledNode(this.treeSelectList.concat())
    },
    checkedChildrenNode (data, checkedKeys) {
      // console.log(`--checkedKeys-----------${checkedKeys}`);
      // console.log(`--data-----------${data.map(v => v.id)}`);
      data.forEach(item => {
        this.remove(checkedKeys, item.menu_id)
        if (item.children && item.children.length > 0) {
          this.checkedChildrenNode(item.children, checkedKeys)
        }
      })
    },
    remove (arr, menu_id) {
      var index = arr.findIndex(i => i === menu_id)
      if (index >= 0) {
        arr.splice(index, 1)
      }
    },
    // 提交表单
    submitForm () {
      const that = this
      this.$refs.queryFormRef.validate((valid) => {
        if (valid) {
          const filterMenue = this.treeSelectList.filter(item => {
            return !item.is_screen
          })
          // this.addForm.children = this.treeSelectList
          this.addForm.children = filterMenue
          if (that.isedit) {
            that.requestupdateRole()
          } else {
            that.requestAddRole()
          }
        } else {
          return false
        }
      })
    },
    // resetForm('queryForm')重置
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    // 根据选中的id，设置节点的选中状态
    loopAdd (oldArray, newArray) {
      // console.log(oldArray);
      for (const item of oldArray) {
        if (
          (item.children !== null &&
            typeof item.children !== 'undefined' &&
            item.children.length === 0) ||
          item.children === null ||
          typeof item.children === 'undefined'
        ) {
          if (this.menuList.indexOf(item.menu_id) !== -1) newArray.push(item.menu_id)
        } else {
          this.loopAdd(item.children, newArray)
        }
      }
    },
    // // 获取树控件
    // async requestRolePermisionList() {
    //   const res = await request.rolePermisionList();
    //   this.treeSelectList = res.Code === 200 ? res.Data : [];
    //   // 设置选中节点
    //   const newArray = [];
    //   this.loopAdd(res.Data, newArray);
    //   this.menuList = newArray;
    //   // 设置禁用状态节点
    //   this.treeSelectList = this.setDisabledNode(res.Data);
    // },
    // 获取树控件
    async requestRoleDetailList () {
      const params = {}
      if (this.isedit) {
        params._id = this.id
      } else {
        params._id = ''
      }
      const res = await request.roleDetailList(params)
      this.treeSelectList = res.Code === 200 ? res.Data : []
      // 设置选中节点
      const newArray = []
      this.loopAdd(res.Data, newArray)
      this.menuList = newArray
      this.addForm.children = this.treeSelectList
      // console.log('newArray', newArray)
      // console.log(' get this.treeSelectList', this.treeSelectList)
      const filterList = this.filterChildren(this.treeSelectList)
      this.treeSelectList = filterList
      // console.log('filter', filterList)
      // 设置禁用状态节点
      // this.treeSelectList = this.setDisabledNode(res.Data);
    },
    filterChildren (arr) {
      arr.forEach(item => {
        if (item.children.length > 0) {
          this.filterChildren(item.children)
        } else {
          delete item.children
        }
      })
      return arr
    },
    // 设置禁用状态节点
    // setDisabledNode(data) {
    //   data.forEach((item) => {
    //     item.disabled = false;
    //     if (item.menu_name === '查看' && data.length > 1 && this.addForm.menu_list.length > 0) {
    //       var num = 0;
    //       data.forEach(j => {
    //         this.addForm.menu_list.forEach((menu) => {
    //           if (j.menu_id === menu) num++;
    //           if (menu === item.menu_id && num > 1) {
    //             item.disabled = true;
    //           }
    //         })
    //       })
    //     } else {
    //       item.disabled = false;
    //     }
    //     if (item.children && item.children.length > 0) {
    //       this.setDisabledNode(item.children);
    //     }
    //   })
    //   return data;
    // },
    // 判断角色名称是否重复
    async requestVerifyRoleNameisReuse (callback) {
      const params = { role_name: this.addForm.role_name }
      if (this.isedit) {
        params.role_id = this.id
      }
      const res = await request.verifyRoleNameisReuse(params)
      if (res.Code !== 200) {
        callback(new Error('角色名重复'))
      } else {
        callback()
      }
    },
    // 新增角色请求
    async requestAddRole () {
      // console.log('新增角色', this.addForm)
      const res = await request.addRoleNew(this.addForm)
      if (res && res.Code === 200) {
        this.$emit('closeDialog', false)
      }
    },
    // 编辑角色请求
    async requestupdateRole () {
      this.addForm._id = this.id
      // console.log('编辑角色', this.addForm)
      const res = await request.updateRoleNew(this.addForm)
      if (res && res.Code === 200) {
        this.defaultForm()
      }
    },
    renderContent (h, { node, data }) {
      let className = ''

      if (data.is_btn) {
        if (node.level === 4) {
          className = 'threeFuse'
        } else if (node.level === 3) {
          className = 'threeFuse threeFuseFrank'
        }
      }
      return <span class={className}>
        {data.show_name}</span>
    },
    // 修改树状图样式
    addTreeStyle (list, frankList) {
      const fuseList = [...list]
      const fuseFrankList = [...frankList]
      const appendElemt = '<div class="appendFrank"></div>'
      // 给二级按钮添加空格样式
      for (var j = 0; j < fuseFrankList.length; j++) {
        const parentElement = fuseFrankList[j].parentNode.parentNode.parentNode
        var existingElement = parentElement.parentNode.firstChild.nextElementSibling
        // 判断dom父级的第二个元素的class是否相同为appendFrank，相同不添加
        if (existingElement.getAttribute('class') !== 'appendFrank') {
          parentElement.insertAdjacentHTML('beforebegin', appendElemt)
        }
      }
      // 给三级按钮添加flex-row样式
      for (var i = 0; i < fuseList.length; i++) {
        fuseList[i].parentNode.parentNode.parentNode.style.display = 'flex'
        fuseList[i].parentNode.parentNode.parentNode.style.flexDirection = 'row'
        fuseList[i].parentNode.parentNode.parentNode.style.flexWrap = 'wrap'
        fuseList[i].parentNode.parentNode.style.border = '0'
        fuseList[i].parentNode.parentNode.style.width = '14%'
        fuseList[i].parentNode.style.width = '90%'
        fuseList[i].parentNode.nextSibling.style.display = 'none'
        fuseList[i].parentNode.style.border = '0'
      }
    }
  },
  beforeDestroy: function () {
    sessionStorage.removeItem('role-edit')
  }
}
</script>

<style scoped lang='less'>
.el-select {
  width: 100%;
}

.titlediv {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 18px;
  color: black;
  margin: 5px;
  font-weight: 700;
  width: "100%";
}

// .el-tree {
//     /deep/ .el-tree-node__children {
//         .el-tree-node__children {
//             display: flex;
//             flex-wrap: wrap;

//             .el-tree-node {
//                 width: 250px;
//             }

//             .el-tree-node__children {
//                 display: flex;
//                 flex-direction: column;
//             }
//         }
//     }
// }
//-----
/deep/.el-tree>.el-tree-node{
  border-bottom: 1px solid #E5E7ED;
}
/deep/.el-tree {
  border-top: 1px solid #E5E7ED;
  border-left: 1px solid #E5E7ED;
  border-right: 1px solid #E5E7ED;
  width: 98%;
  margin-bottom: 40px;
  box-sizing: border-box;
  .el-tree-node__content{
    // margin-top: 10px;
    margin-bottom: unset;
    width: 190px;
    overflow: hidden;
    align-items: start;
    flex-shrink: 0;
  }
  .appendFrank{
    width: 190px;
    padding: 5px 10px !important;
    border-left: unset !important;
    overflow: hidden;
    border-right: 1px solid #E5E7ED;
    flex-shrink: 0;
  }
  // .el-tree-node__label {
  //   // margin: 10px 70px 10px 0;
  // }

  .el-tree-node {
    .el-tree-node {
      .el-tree-node__children {
        width: 100%;
      }

      // .el-tree-node {
      //   &:not(:first-child) {
      //     .el-tree-node__content {

      //       // border-right: 1px solid #E5E7ED;
      //     }
      //     // border-top: 1px solid #E5E7ED;
      //   }
      // }

    }
  }

  .addclass {
    .el-tree-node:not(:first-child) {
      .el-tree-node__content {
        border-left: none !important;
      }
    }
  }

  .el-tree-node__content {
    padding: 5px 10px !important;
    height: auto;
    // 展开按钮
    .el-tree-node__expand-icon.el-icon-caret-right {
      display: none;
    }
  }

  .el-tree-node {
    padding: 0 !important;
    display: flex;
    // &:last-child{
    //   border-bottom: 1px solid #E5E7ED;
    // }
    // border-bottom: 1px solid #E5E7ED;
    .el-tree-node__children {
      width: 100%;

      .el-tree-node {
        &:not(:first-child) {
          border-top: 1px solid #E5E7ED;
        }
        // &:not(:last-child){
        //   border-bottom: 1px solid #E5E7ED;
        // }
        .el-tree-node__content {
          border-left: 1px solid #E5E7ED;
          border-right: 1px solid #E5E7ED;
        }
      }
    }
  }

  /deep/ .addclass {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    .el-tree-node {
      border-top: none !important;
    }
  }

  .el-tree-node__children {
    display: flex;
    flex-direction: column;
    .el-tree-node {
      display: flex;
      padding: 0px !important;
      .el-tree-node__content {
        border-bottom: none;
        .custom-tree-node {
          height: 24px;
          display: flex;
          align-items: center;
          .tree-btn {
            margin-left: 10px;
            display: none;
          }
          .el-button {
            padding: 0px !important;
            border-radius: 4px;
            background: #363554;
            color: #ffffff;
          }
        }
      }

      // .el-tree-node__children {
      //   .el-tree-node {
      //     &:not(:first-child) {
      //       .el-tree-node__content {
      //         border-left: none;
      //       }
      //     }
      //   }
      // }
      .el-tree-node__children {
        .el-tree-node {

            .el-tree-node__content {
              border-left: none;
            }
        }
      }
    }
  }
}
// end

.box-card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 30px;

  .checkButton {
    position: absolute;
    right: 10px;
    bottom: 0;
  }

  .checkButton2 {
    position: absolute;
    right: 110px;
    bottom: 0;
  }

  .contents {
    flex: 1;
    height: 100%;

    // height: 500px;
    .tree-style {
      height: 480px;
      overflow-y: auto;
    }
  }
}

.el-form {
  display: flex;
  justify-content: start;
}</style>
